import { useEffect } from 'react';

import loadable from '@loadable/component';
import { redirect } from 'saddlebag-browser';

import BpkGraphicPromo, {
  TEXT_ALIGN,
} from '@skyscanner/backpack-web/bpk-component-graphic-promotion';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import FooterBlurb from '../common/FooterBlurb';
import MBD from '../common/MBD/MBD';

import type { GraphicPromotionProps } from 'common-types/types/GraphicPromotionProps';

import STYLES from './GraphicPromotion.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const gradient = `linear-gradient(90deg, rgba(22, 22, 22, 0.45) 0%, rgba(22, 22, 22, 0) 100%)`;

const handleClick = (content: string, url: string) => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.APP_BANNER,
    component_action: COMPONENT_ACTION.APP_BANNER.APP_BANNER_CLICKED,
    link_info: {
      url,
      content,
    },
  });

  redirect(url);
};

const GraphicPromotion = (props: GraphicPromotionProps) => {
  const {
    background,
    buttonText,
    componentName,
    disclaimer,
    hasGradient = true,
    heading,
    imageAlign,
    subheading,
    textAlign,
    url,
  } = props;

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.APP_BANNER,
      load_status: LOAD_STATUS.LOADED,
    });
  }, []);

  const mbdName = componentName || 'seo_graphic_promotion_banner';

  return (
    <MBD id={mbdName} componentName={mbdName}>
      <div className={STYLES.GraphicPromotion}>
        <BpkGraphicPromo
          buttonText={buttonText}
          headline={heading}
          subheading={subheading}
          onClick={() => handleClick(buttonText, url)}
          style={{
            backgroundImage: hasGradient
              ? `${gradient}, url(${background})`
              : `url(${background})`,
            backgroundPositionX: imageAlign || 'center',
          }}
          textAlign={textAlign || TEXT_ALIGN.start}
          invertVertically
        />
        {disclaimer && (
          <div className={STYLES.GraphicPromotion__disclaimer}>
            <FooterBlurb disclaimerCopy={disclaimer} />
          </div>
        )}
      </div>
    </MBD>
  );
};

export default (props: GraphicPromotionProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.APP_BANNER,
      })
    }
  >
    <GraphicPromotion {...props} />
  </IntersectionObserverWrapper>
);
